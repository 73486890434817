/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  margin-left: -2px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  right: 12px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  right: 2px;
  left: auto;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #ffc1a6;
  border-radius: 2px;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #ffc1a6;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle-dragging {
  z-index: 1;
}
.ant-slider-handle:focus {
  border-color: #f17454;
  outline: none;
  box-shadow: 0 0 0 5px rgba(237, 81, 41, 0.12);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #ed5129;
}
.ant-slider-handle::after {
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
  content: '';
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #ffa07d;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #ffa07d;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  user-select: none;
}
.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.65);
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
  pointer-events: none;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot-active {
  border-color: #f6a894;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-rail {
  background-color: #f5f5f5 !important;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
.ant-slider-rtl {
  direction: rtl;
}
.ant-slider-rtl .ant-slider-mark {
  right: 0;
  left: auto;
}
